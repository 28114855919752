import React, { useEffect, useMemo } from 'react';

import { Button, Col, Form, FormInstance, Input, Select, DatePicker } from 'antd';

import {
  AMERICAN_DATE_FORMAT,
  MODAL_FORM_LAYOUT_CONTROL_OFFSET,
  FORM_LAYOUT_CONTROL_OFFSET,
  FORM_LAYOUT_LABEL,
  MODAL_FORM_LAYOUT_LABEL,
} from '../../utils/midas-constants';
import { DEFAULT_CRITERIA, mapFieldToInputType, MIN_SEARCH_CRITERIA_WITH_DATE } from '../_shared/advanced-search-constants';

import classes from './ReferenceDataFormFields.module.css';
import { AdvancedProductSearchCriteria, AdvancedProductSearchResultHeader } from './AdvancedProductSearchForm';
import { DataQueryProducts } from '../../pages/types';
import { PlusOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';

type ReferenceDataFormFieldsParams = {
  formInstance: FormInstance;
  refDataTables: AdvancedProductSearchCriteria[];
  dataSource?: string;
  resultHeaders: AdvancedProductSearchResultHeader;
  searchCriteria: AdvancedProductSearchCriteria[];
  setDataSource: (value: string | undefined) => void;
  setProducts: (fn: (value: DataQueryProducts) => DataQueryProducts) => void;
  setSearchCriteria: (
    value: AdvancedProductSearchCriteria[] | ((value: AdvancedProductSearchCriteria[]) => AdvancedProductSearchCriteria[])
  ) => void;
  embedded: boolean;
};

type FormReferenceDataFilter = { fieldName: string | undefined; value: string | number | Dayjs | undefined };

export default function ReferenceDataFormFields({
  formInstance,
  refDataTables,
  dataSource,
  resultHeaders,
  searchCriteria,
  setDataSource,
  setSearchCriteria,
  embedded,
}: ReferenceDataFormFieldsParams) {
  const referenceDataSource = Form.useWatch<string | undefined>('referenceDataSource', formInstance);
  const referenceDataDate = Form.useWatch<Dayjs | undefined>('referenceDataDate', formInstance);
  const referenceDataFilters = Form.useWatch<(FormReferenceDataFilter | undefined)[] | undefined>('referenceDataFilters', formInstance);

  const dataSourceHeadersMap = useMemo(() => {
    if (!resultHeaders || !dataSource || !resultHeaders[dataSource]?.length) {
      return undefined;
    }
    return new Map(resultHeaders[dataSource].map((header) => [header.name, header.fieldType]));
  }, [resultHeaders, dataSource]);

  // compute the search criteria
  useEffect(() => {
    if (!dataSourceHeadersMap || !referenceDataFilters) return;

    const searchCriteria: AdvancedProductSearchCriteria[] = [
      {
        field: DEFAULT_CRITERIA.find((name) => dataSourceHeadersMap.has(name)),
        type: 'date',
        value: referenceDataDate,
      },
      ...referenceDataFilters
        .filter<FormReferenceDataFilter>((f): f is FormReferenceDataFilter => !!f)
        .map(({ fieldName, value }) => ({
          field: fieldName,
          type: getFilterValueType(fieldName),
          value,
        })),
    ];

    setSearchCriteria(searchCriteria);
  }, [referenceDataDate, dataSourceHeadersMap, referenceDataFilters, setSearchCriteria]);

  const getFilterValueType = (fieldName: string | undefined) => {
    if (!fieldName || !dataSourceHeadersMap) return 'text';

    const selectedFieldType = dataSourceHeadersMap.get(fieldName);
    return selectedFieldType ? mapFieldToInputType(fieldName, selectedFieldType) : 'text';
  };

  const wrapperCol = useMemo(() => (embedded ? FORM_LAYOUT_CONTROL_OFFSET : MODAL_FORM_LAYOUT_CONTROL_OFFSET), [embedded]);

  const remainingSearchCriteriaOptions = useMemo(() => {
    const searchCriteriaHeaders = searchCriteria.map((criterion) => criterion.field);
    return resultHeaders?.[dataSource!]
      ?.filter(({ name }) => {
        return !(name.toLowerCase() === 'date');
      })
      .map(({ name }) => ({
        disabled: searchCriteriaHeaders.includes(name),
        label: name,
        value: name,
      }));
  }, [resultHeaders, searchCriteria, dataSource]);

  useEffect(() => setDataSource(referenceDataSource), [referenceDataSource]);

  const onDataSourceChange = () => formInstance.resetFields(['referenceDataFilters']);

  return (
    <>
      <Form.Item
        label="Data Source"
        labelAlign="left"
        name="referenceDataSource"
        data-testid="data-query-search-reference-data-source"
        rules={[{ required: true, message: 'Select a data source!' }]}
      >
        <Select
          loading={!refDataTables.length}
          onChange={onDataSourceChange}
          disabled={!refDataTables.length}
          placeholder="Select a data source"
        >
          {refDataTables.map(({ id, type, displayName }) => (
            <Select.Option key={id} value={type}>
              {displayName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item rules={[{ required: true, message: 'Enter a search date' }]} name="referenceDataDate" label="Date" labelAlign="left">
        <DatePicker format={AMERICAN_DATE_FORMAT} aria-label="Date picker" />
      </Form.Item>

      {referenceDataSource && (
        <Form.List name="referenceDataFilters">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div key={`reference-data-filter-${key}`} className={classes.searchCriteriaFormGroup}>
                  {/* Filter label */}
                  <Col {...(embedded ? FORM_LAYOUT_LABEL : MODAL_FORM_LAYOUT_LABEL)} style={{ padding: 0 }}>
                    <label htmlFor={`reference-data-filter-${key}`}>
                      {name < MIN_SEARCH_CRITERIA_WITH_DATE ? <span className={classes.starForRequired}>*</span> : <></>}
                      Filter {name}
                    </label>
                  </Col>

                  {/* Filter field name */}
                  <Form.Item {...restField} name={[name, 'fieldName']} rules={[{ required: true, message: 'Enter a key here' }]}>
                    <Select options={remainingSearchCriteriaOptions} placeholder="Select a field" aria-label="Select a field" />
                  </Form.Item>

                  {/* Filter value */}
                  <Form.Item {...restField} name={[name, 'value']} rules={[{ required: true, message: 'Enter a value here' }]}>
                    <Input
                      placeholder="Enter a search value"
                      type={getFilterValueType(formInstance.getFieldValue(['referenceDataFilters', name, 'fieldName']))}
                    />
                  </Form.Item>

                  <Button
                    disabled={key < MIN_SEARCH_CRITERIA_WITH_DATE}
                    className={key < MIN_SEARCH_CRITERIA_WITH_DATE ? classes.invisibleRemove : ''}
                    type="link"
                    onClick={() => remove(name)}
                  >
                    Remove
                  </Button>
                </div>
              ))}
              <Form.Item wrapperCol={wrapperCol}>
                <Button
                  disabled={(referenceDataFilters?.length ?? 0) >= (remainingSearchCriteriaOptions?.length ?? 0)}
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                >
                  Add Filter
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )}
    </>
  );
}
