import React, { useCallback, useEffect, useMemo } from 'react';

import { Button, Drawer, Form, Select, Tooltip } from 'antd';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import startCase from 'lodash/startCase';

dayjs.extend(customParseFormat);

import SearchPlaceholder from '../../../../../../components/SearchPlaceholder';
import classes from './GraphControlPanel.module.css';
import { DataQueryConfig, DataQueryConfigViz, DataQueryProductColumn, DataQueryProductSampleData } from '../../../../../types';
import { InfoCircleOutlined } from '@ant-design/icons';

type GraphControlPanelParams = {
  columnMetadata: DataQueryProductColumn[];
  config: DataQueryConfig;
  onConfigChanged?: (config: DataQueryConfig) => void;
  onClose?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
  isOpen?: boolean;
  placement?: 'top' | 'bottom' | 'right' | 'left';
  sampleData?: DataQueryProductSampleData[];
};

const numbers = ['int', 'double', 'float', 'bigint', 'mediumint'];
const times = ['dateticks', 'date', 'datetime', 'time', 'timestamp'];
const numbersOrTime = [...numbers, ...times];

const suggestedVisibleExclusions = ['id', 'key', ...times];

const initializeGraphData = (numericOrTimeColumns: string[], numericColumns: string[]) => {
  let suggestedValueIndex = -1;

  for (const suggestedValue of times) {
    suggestedValueIndex = numericColumns.findIndex((numericColumn) => numericColumn.toLowerCase() === suggestedValue);

    if (suggestedValueIndex !== -1) {
      break;
    }
  }

  return {
    indexColumn: numericOrTimeColumns[Math.max(suggestedValueIndex, 0)], //x axis
    visibleColumns: numericColumns.filter((numericColumn) => !suggestedVisibleExclusions.includes(numericColumn.toLowerCase())),
  };
};

// eslint-disable-next-line default-param-last
const hasValidNumericColumns = (vizConfig: DataQueryConfigViz = {}, numericColumns: string[]) => {
  const { indexColumn = undefined, visibleColumns = [] } = vizConfig;

  return indexColumn ? numericColumns.includes(indexColumn) : undefined; //x axis
};

// eslint-disable-next-line default-param-last
const hasValidNumericOrTimeColumns = (vizConfig: DataQueryConfigViz = {}, numericOrTimeColumns: string[]) => {
  const { indexColumn = undefined, visibleColumns = [] } = vizConfig;

  return visibleColumns.every((column) => numericOrTimeColumns.includes(column)); //y axis
};

const columnHasNumberValue = (name: string, data: DataQueryProductSampleData[]) => {
  if (!name || !data || !data.length) {
    return false;
  }

  const [sampleRow] = data;

  return !Number.isNaN(Number(sampleRow[name as keyof DataQueryProductSampleData]));
};

const columnHasNumberOrTimeValue = (name: string, data: DataQueryProductSampleData[]) => {
  if (!name || !data || !data.length) {
    return false;
  }
  const [sampleRow] = data;
  return (
    !Number.isNaN(Number(sampleRow[name as keyof DataQueryProductSampleData])) ||
    dayjs(sampleRow[name as keyof DataQueryProductSampleData], 'YYYYMMDD', true).isValid() ||
    dayjs(sampleRow[name as keyof DataQueryProductSampleData], 'HHmmss', true).isValid()
  );
};

const { useForm } = Form;

const DEFAULT_PROPS = {
  onConfigChanged: () => {},
  onClose: () => {},
  sampleData: [],
};

export default function GraphControlPanel({
  columnMetadata,
  config,
  onConfigChanged = DEFAULT_PROPS.onConfigChanged,
  isOpen = false,
  onClose = DEFAULT_PROPS.onClose,
  placement = 'top',
  sampleData = DEFAULT_PROPS.sampleData,
}: GraphControlPanelParams) {
  const [formInstance] = useForm();
  const numericColumns = useMemo(
    () =>
      columnMetadata
        .filter(({ name, fieldType }) => numbers.includes(fieldType) || columnHasNumberValue(name, sampleData))
        .map(({ name }) => name),
    [columnMetadata, sampleData]
  );
  const numericOrTimeColumns = useMemo(
    () =>
      columnMetadata
        .filter(({ name, fieldType }) => numbersOrTime.includes(fieldType) || columnHasNumberOrTimeValue(name, sampleData))
        .map(({ name }) => name),
    [columnMetadata, sampleData]
  );

  const { vizConfig = undefined } = config;

  useEffect(() => {
    if (
      !numericColumns.length ||
      !numericOrTimeColumns.length ||
      (vizConfig && hasValidNumericColumns(vizConfig, numericColumns) && hasValidNumericOrTimeColumns(vizConfig, numericOrTimeColumns))
    ) {
      return;
    }

    onConfigChanged({
      ...config,
      vizConfig: initializeGraphData(numericOrTimeColumns, numericColumns),
    });
  }, [numericOrTimeColumns, numericColumns]);

  useEffect(() => formInstance.setFieldsValue(config.vizConfig), [formInstance, config]);

  const onFinish = useCallback(
    (data: DataQueryConfigViz) => {
      onConfigChanged({
        ...config,
        vizConfig: data,
      });

      if (onClose) {
        onClose();
      }
    },
    [onConfigChanged, onClose, config]
  );

  const searchPlaceholder = <SearchPlaceholder text="Please enter the column names..." />;

  return (
    <Drawer
      title="Configure Graph"
      placement={placement}
      closable={onClose !== undefined}
      onClose={onClose}
      forceRender
      open={isOpen}
      getContainer={false}
      rootStyle={{ position: 'absolute' }}
      height="330px"
    >
      <Form rootClassName={classes.graphControlForm} form={formInstance} onFinish={onFinish}>
        <Form.Item
          label={
            <span>
              Visible Columns &nbsp;
              <Tooltip title="Y axe - only numeric columns allowed - multiple series allowed">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          name="visibleColumns"
          rules={[{ required: true, message: 'This component is required!' }]}
        >
          <Select rootClassName={classes.columnSelect} mode="multiple" placeholder={searchPlaceholder}>
            {numericColumns.map((item) => (
              <Select.Option key={item} value={item}>
                {startCase(item)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <span>
              Index Columns &nbsp; &nbsp;
              <Tooltip title="X axe - numeric and time columns allowed - only one field allowed">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          name="indexColumn"
          rules={[{ required: true, message: 'This component is required!' }]}
        >
          <Select rootClassName={classes.columnSelect} placeholder={searchPlaceholder}>
            {numericOrTimeColumns.map((item) => (
              <Select.Option key={item} value={item}>
                {startCase(item)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item label="Group By" name="groupByColumns">
          <Select autoFocus>
            {[...numericColumns, ...stringColumns].map((item) => (
              <Select.Option key={item} value={item}>
                {startCase(item)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item>
          <Button rootClassName={classes.submitButton} htmlType="submit" type="primary">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
