import { createContext } from 'react';
import { DataQueryFormItem } from '../pages/types';

export type ISettingsConfigContext = {
  appConfig: DataQueryFormItem;
  updateAppConfig: (config: DataQueryFormItem | ((value: DataQueryFormItem) => DataQueryFormItem)) => void;
  setAppConfig?: () => Promise<void>;
  updateTabName: (uuid: string, name: string) => void;
  updateTabById: (uuid: string | undefined, newConfig: DataQueryFormItem | ((data: DataQueryFormItem) => DataQueryFormItem)) => void;
  getTabById: (id: string) => DataQueryFormItem | undefined;
  createTab: (routeType: string, initialConfig?: DataQueryFormItem) => Promise<void>;
  createOrSelectTab?: (routeType: string) => void;
  closeTab?: (routeToDelete: string) => Promise<boolean> | boolean;
  closeDataTab?: (dpTableName: string) => boolean | Promise<boolean>;
};

const notImpl = () => {
  throw new Error('Not Implemented!');
};

const notImplAsync = async () => {
  throw new Error('Not Implemented!');
};

const settingsConfigContext: ISettingsConfigContext = {
  appConfig: {
    tabs: [],
    previousSearches: [],
    searchConfigs: [],
  },
  updateAppConfig: () => {},
  setAppConfig: () => Promise.resolve(),
  updateTabName: notImpl,
  updateTabById: notImpl,
  getTabById: notImpl,
  createTab: notImplAsync,
};

export const SettingsConfigContext = createContext(settingsConfigContext);
