/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosResponse } from 'axios';
import { getLogger } from '../logging';
import { LinkedError } from './linked-error';
import util from 'util';

const logger = getLogger({ file: __filename });

/**
 * Removes circular references (especially needed in Jest tests: https://github.com/jestjs/jest/issues/10577) and unnecessary fields
 */
function cleanError(error: object) {
  // axios requests can log the request headers and data:
  // as those can contain sensitive (or very large) data, we omit them
  if ('config' in error) {
    const cleanedConfig = { ...(error.config as object) };
    if ('data' in cleanedConfig) {
      cleanedConfig.data = '<omitted>';
    }
    if ('headers' in cleanedConfig) {
      cleanedConfig.headers = '<omitted>';
    }
    error.config = cleanedConfig;
  }

  if ('request' in error) {
    const { _options, _ended, _ending, _redirectCount, _currentUrl } = error.request as { [k: string]: any };
    if (_options && 'nativeProtocols' in _options) {
      _options.nativeProtocols = undefined; // useless info
    }
    error.request = { _options, _ended, _ending, _redirectCount, _currentUrl };
  }

  try {
    JSON.stringify(error);
  } catch (err: unknown) {
    // if we didn't catch a circular reference, serialize the entire object
    return util.inspect(error, false, 4);
  }

  return error;
}

/*
  Note: with the following error handling improvement introduced in axios v1.0
  we might be able to simplify or remove these functions:
  https://github.com/axios/axios/pull/4624
*/

export async function handleAxiosError<T>(
  response: Promise<AxiosResponse<T>>
): Promise<T | LinkedError<{ responseStatus?: number; responseData?: unknown }>> {
  const responseOrError = await response.catch((err: Error) => err);

  if (responseOrError instanceof Error) {
    const error = cleanError({ ...responseOrError });
    logger.debug(responseOrError, 'request error');
    return new LinkedError('request error', undefined, error as any);
  } else if (responseOrError.status < 200 || responseOrError.status > 299) {
    logger.debug(
      {
        responseStatus: responseOrError.status,
        url: responseOrError.config.url,
        method: responseOrError.config.method,
        responseData: responseOrError.data,
      },
      'request failed'
    );
    return new LinkedError('request status error', undefined, {
      responseStatus: responseOrError.status,
      responseData: responseOrError.data,
    });
  } else {
    return responseOrError.data;
  }
}

/**
 * Given an axios response, throws an error in case the status is not in 200-299 range.
 * The error preserves stack information and deletes the headers & posted data.
 *
 * Assumes that response was created with the { validateStatus: null } option set.
 *
 * @param response the axios response.
 * @returns the response data.
 */
export async function throwAxiosError<T>(response: Promise<AxiosResponse<T>>): Promise<T> {
  const responseOrError = await handleAxiosError(response);
  if (responseOrError instanceof LinkedError) {
    throw responseOrError;
  }

  return responseOrError;
}

/**
 * Given an axios response, logs an error in case the status is not in 200-299, and returns undefined.
 * The logged error preserves stack information and deletes the headers & posted data.
 *
 * Assumes that response was created with the { validateStatus: null } option set.
 *
 * @param response the axios response.
 * @param errorMessage the message to log along with the exception.
 * @returns the response data, or undefined in case of error.
 */
export async function checkAxiosResponse<T>(
  response: Promise<AxiosResponse<T>>,
  errorMessage = 'request failed',
  options?: { silent?: boolean }
): Promise<T | undefined> {
  const responseOrError = await handleAxiosError(response);
  if (responseOrError instanceof LinkedError) {
    if (!options?.silent) {
      logger.error(responseOrError, errorMessage);
    }
    return undefined;
  }

  return responseOrError;
}
