import { ErrorNotification } from '../../../services/error-notification';
import { ResourceActionType, UserInstancesUsage, UserUsage, Usage, UsageApi, OrganisationUsage, OverageUsage } from '../usage-api';

export interface UsageModel extends OrganisationUsage {
  loading: boolean;
  isDownloadingOrgReport: boolean;
  downloadingUserReportList: string[] | undefined;
  level: ResourceActionType | undefined;
  userInstancesUsage: UserInstancesUsage | undefined;
  sortBy: 'compute' | 'egress' | 'storage' | 'user';
  sortDirection: 'asc' | 'desc';
}

export function initialUsageModel(): UsageModel {
  return {
    loading: true,
    isAdmin: false,
    isDownloadingOrgReport: false,
    downloadingUserReportList: undefined,
    units: undefined,
    limits: undefined,
    level: undefined,
    userUsage: undefined,
    userInstancesUsage: undefined,
    sortBy: 'user',
    sortDirection: 'asc',
  };
}

function getUsageSum(usages: Usage[]): Usage {
  return usages.reduce(
    (acc, currVal) => ({
      compute: acc.compute + currVal.compute,
      egress: acc.egress + currVal.egress,
      storage: acc.storage + currVal.storage,
    }),
    { compute: 0, egress: 0, storage: 0 }
  );
}

export function getTotalUsage(usage: UserUsage[]): Usage {
  return getUsageSum(usage);
}

export function getTotalOverageCosts(usage: UserUsage[]): Usage | undefined {
  const overageCosts = usage.map((u) => u.overageCosts).filter(Boolean) as OverageUsage[];
  return !overageCosts.length ? undefined : getUsageSum(overageCosts);
}

export function getTotalUsageCosts(usage: UserUsage[]): Usage | undefined {
  const usageCosts = usage.map((u) => u.usageCosts).filter(Boolean) as Usage[];
  return !usageCosts.length ? undefined : getUsageSum(usageCosts);
}

export function asUserInstanceUsageUpdate(model: () => UsageModel, update: (_: UsageModel) => void) {
  return (userInstancesUsage: UserInstancesUsage | undefined, loading: boolean) => update({ ...model(), userInstancesUsage, loading });
}

export async function loadUserInstanceUsage(
  usageApi: UsageApi,
  userId: string,
  update: (userInstanceUsage: UserInstancesUsage | undefined, loading: boolean) => void
): Promise<ErrorNotification | undefined> {
  update(undefined, true);

  const userInstanceUsage = await usageApi.getUserInstanceUsage(userId);
  if (!userInstanceUsage) {
    update(undefined, false);
    return { message: 'Error retrieving instance breakdown' };
  }
  update(userInstanceUsage, false);
}

export async function downloadOrganisationAggregatedUsageCSVReport(
  usageApi: UsageApi,
  getModel: () => UsageModel,
  update: (_: UsageModel) => void
): Promise<ErrorNotification | undefined> {
  const model = getModel();
  const reportName = 'Organisation Aggregated Usage Report.csv';
  const error = { message: `Error while getting ${reportName}` };
  try {
    update({ ...model, isDownloadingOrgReport: true });
    const res = await usageApi.getOrganisationAggregatedUsageCSVReport();
    if (!res) {
      return error;
    }
    downloadCSVReport(res, reportName);
  } catch (e) {
    console.error(e);
    return error;
  } finally {
    update({ ...model, isDownloadingOrgReport: false });
  }
}

export async function downloadOrganisationDetailedUsageCSVReport(
  usageApi: UsageApi,
  getModel: () => UsageModel,
  update: (_: UsageModel) => void
): Promise<ErrorNotification | undefined> {
  const model = getModel();
  const reportName = 'Organisation Detailed Usage Report.csv';
  const error = { message: `Error while getting ${reportName}` };
  try {
    update({ ...model, isDownloadingOrgReport: true });
    const res = await usageApi.getOrganisationDetailedUsageCSVReport();
    if (!res) {
      return error;
    }
    downloadCSVReport(res, reportName);
  } catch (e) {
    console.error(e);
    return error;
  } finally {
    update({ ...model, isDownloadingOrgReport: false });
  }
}

export async function downloadUserAggregatedUsageCSVReport(
  usageApi: UsageApi,
  userId: string,
  getModel: () => UsageModel,
  update: (_: UsageModel) => void
): Promise<ErrorNotification | undefined> {
  const model = getModel();
  const reportName = 'User Aggregated Usage Report.csv';
  const error = { message: `Error while getting ${reportName}` };
  try {
    update({ ...model, downloadingUserReportList: [...(model.downloadingUserReportList || []), userId] });
    const res = await usageApi.getUserUsageCSVReport(userId);
    if (!res) {
      return error;
    }
    downloadCSVReport(res, reportName);
  } catch (e) {
    console.error(e);
    return error;
  } finally {
    update({ ...model, downloadingUserReportList: model.downloadingUserReportList?.filter((el) => el !== userId) });
  }
}

function downloadCSVReport(res: Blob, reportName: string): void {
  const href = URL.createObjectURL(res);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', reportName);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export async function onSortBy(
  sortBy: 'compute' | 'egress' | 'storage' | 'user',
  usageApi: UsageApi,
  getModel: () => UsageModel,
  update: (_: UsageModel) => void
): Promise<void> {
  const m = { ...getModel() };
  m.sortBy = sortBy;

  if (sortBy === 'user') {
    const res = await usageApi.getOrganisationUsage();
    if (res) {
      update({
        ...m,
        ...res,
      });
    }
    return;
  }

  m.userUsage = m.userUsage?.sort((a, b) => {
    if (a[sortBy] > b[sortBy] && m.sortDirection === 'desc') {
      return -1;
    }
    if (a[sortBy] < b[sortBy] && m.sortDirection === 'asc') {
      return -1;
    }
    return 0;
  });
  update(m);
}

export async function onSortDirection(
  sortDirection: 'asc' | 'desc',
  usageApi: UsageApi,
  getModel: () => UsageModel,
  update: (_: UsageModel) => void
): Promise<void> {
  const m = { ...getModel() };
  m.sortDirection = sortDirection;
  if (m.sortBy === 'user') {
    const res = await usageApi.getOrganisationUsage();
    if (res) {
      update({
        ...m,
        ...res,
      });
    }
    return;
  }
  m.userUsage = m.userUsage?.reverse();

  update(m);
}
