import dayjs from 'dayjs';

// use dayjs('20200629') for localhost data
export const INITIAL_SEARCH_DATE = dayjs().subtract(1, 'days');

export const SUPPORTED_SOURCES = {
  dataSourceEtfg: 'etfg',
  dataSourceTaqMaster: 'taqmaster',
};

export const FORM_VALUES = {
  ...SUPPORTED_SOURCES,
  searchTypeProduct: 'product-data',
  searchTypeReference: 'reference-data',
};

export const MIN_SEARCH_CRITERIA = 2;

export const MIN_SEARCH_CRITERIA_WITH_DATE = MIN_SEARCH_CRITERIA - 1;

export const DEFAULT_PAGE_SIZE = 50;

export const mapFieldToInputType = (fieldName: string, fieldType: string) => {
  if (fieldName === 'date' || fieldType === 'date') {
    return 'date';
  }

  if (fieldType === 'int' || fieldType === 'double') {
    return 'number';
  }

  return 'text';
};

export const DEFAULT_CRITERIA = ['date', 'filedate'];

export const PRODUCT_COLUMNS_TO_SEARCH = [
  { column: 'inet_ticker' },
  { column: 'symbol' },
  { column: 'security_description', searchMethod: 'includes' },
];

// not used anymore, left as reference
export const ETFG_HEADERS = ['date', 'etf_ticker', 'inet_ticker', 'weight'];

export const TAQ_HEADERS = [
  'filedate',
  'inet_ticker',
  'taq_exch',
  'Symbol',
  'Security_Description',
  'CUSIP',
  'Security_Type',
  'SIP_Symbol',
  'Old_Symbol',
  'Test_Symbol_Flag',
  'Listed_Exchange',
  'Tape',
  'Unit_Of_Trade',
  'Round_Lot',
  'NYSE_Industry_Code',
  'Shares_Outstanding',
  'Halt_Delay_Reason',
  'Specialist_Clearing_Agent',
  'Specialist_Clearing_Number',
  'Specialist_Post_Number',
  'Specialist_Panel',
  'TradedOnNYSEMKT',
  'TradedOnNASDAQBX',
  'TradedOnNSX',
  'TradedOnFINRA',
  'TradedOnISE',
  'TradedOnEdgeA',
  'TradedOnEdgeX',
  'TradedOnCHX',
  'TradedOnNYSE',
  'TradedOnArca',
  'TradedOnNasdaq',
  'TradedOnCBOE',
  'TradedOnPSX',
  'TradedOnBATSY',
  'TradedOnBATS',
  'TradedOnIEX',
  'Tick_Pilot_Indicator',
  'Effective_Date',
  'TradedOnLTSE',
];
