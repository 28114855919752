import { TempAccessRequest, WbApi, WbDeploymentType, WbEcsTaskState, WbInstanceData, WbInstanceStatus } from '../workbench-api';

export type AccessInProgressType = 'RETURNING' | 'RETRYING_REQUEST' | 'REMOVING_REQUEST';

export interface WbInstance {
  id: string;
  name: string;
  shortId: string;
  image: string;
  status: WbInstanceStatus;
  statusOverride: WbInstanceStatus | undefined;
  runningJobs: number;
  progress: number | null | undefined; // 0-1
  createdAt: string; // ISO format
  canUpdate: boolean;
  access: TempAccessRequest | undefined;
  accessInProgress: AccessInProgressType | undefined;
  mem: number;
  cpu: number;
  diskSize: number;
  nEcsTasks: number;
  awsCurrentTaskStatus: WbEcsTaskState | null | undefined;
  awsCurrentTaskId: string | null | undefined;
  consecutiveFailures: number | null | undefined;
  isOpenInsideUI: boolean | undefined;
  scheduledPauseDays: number;
  deploymentType?: WbDeploymentType | undefined;
  lastAccessedAt?: string | undefined;
  logoDownloadUrl: string | undefined;
}

export interface NonOwnedInstance {
  id: string;
  name: string;
  createdAt: string;
  performingRetake: boolean;
  status?: WbInstanceStatus;
}

function instanceFromData(existingData: WbInstance[], tempAccessRequests: TempAccessRequest[], data: WbInstanceData): WbInstance {
  const matchingRecord = existingData.find((d) => d.id === data.id);
  const access = tempAccessRequests.find((r) => r.shortId === data.shortId);

  return {
    id: data.id,
    name: data.uiData?.name ?? data.shortId,
    shortId: data.shortId,
    image: data.awsInfo?.ECRImageId ?? 'N/A',
    status: data.currentStatus,
    statusOverride: matchingRecord?.statusOverride,
    runningJobs: data.runningJobs,
    progress: data.provisioningInfo?.progress,
    createdAt: data.created_at,
    canUpdate: data.canUpdate,
    access,
    accessInProgress: matchingRecord?.accessInProgress,
    mem: data.mem,
    cpu: data.cpu,
    diskSize: data.diskSize,
    nEcsTasks: data.nEcsTasks,
    awsCurrentTaskStatus: data.awsCurrentTaskStatus,
    awsCurrentTaskId: data.awsCurrentTaskId,
    consecutiveFailures: data.consecutiveFailures,
    isOpenInsideUI: false,
    scheduledPauseDays: data.scheduledPauseDays,
    deploymentType: data.deploymentType,
    logoDownloadUrl: data.logoDownloadUrl,
  };
}

function instanceFromTempAccessRequest(existingData: WbInstance[], request: TempAccessRequest): WbInstance {
  const matchingRecord = existingData.find((d) => d.id === request.id);

  return {
    id: request.id,
    name: request.shortId,
    shortId: request.shortId,
    image: 'N/A',
    status: 'PROVISIONED',
    statusOverride: undefined,
    runningJobs: 0,
    progress: undefined,
    createdAt: '',
    canUpdate: false,
    access: request,
    accessInProgress: matchingRecord?.accessInProgress,
    mem: 0,
    cpu: 0,
    diskSize: 0,
    nEcsTasks: 1,
    awsCurrentTaskStatus: undefined,
    awsCurrentTaskId: undefined,
    consecutiveFailures: undefined,
    isOpenInsideUI: false,
    scheduledPauseDays: window.CONFIG.defaultScheduledPauseDays,
    logoDownloadUrl: undefined,
  };
}

export async function loadInstances(api: WbApi, getExisting: () => WbInstance[]) {
  const [actualInstances, tempAccess, instancesOwnedByOthersResponse] = await Promise.all([
    api.getInstances(),
    api.getAccessRequests(),
    api.getInstancesOwnedByOthers(),
  ]);
  if (!actualInstances || !tempAccess || !instancesOwnedByOthersResponse) {
    return undefined;
  }

  const existingData = getExisting();
  const instances = actualInstances
    .map((data) => instanceFromData(existingData, tempAccess.requests, data))
    .concat(tempAccess.requests.filter((r) => r.status !== 'APPROVED').map((data) => instanceFromTempAccessRequest(existingData, data)));

  const instancesOwnedByOthers = instancesOwnedByOthersResponse.instances.map(
    (data): NonOwnedInstance => ({
      id: data.id,
      name: data.uiData?.name ?? data.shortId,
      createdAt: data.created_at,
      performingRetake: false,
      status: data.currentStatus,
    })
  );

  return { instances, canRequestTempAccess: tempAccess.canRequest, instancesOwnedByOthers };
}
